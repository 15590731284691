import React from 'react';
import { withTranslation } from 'react-i18next';

import {LandingLayout} from '../../layouts';
import {Hero, Footer, Pricing, References, Reference, Features, Feature, SignupDivider, BookDemo} from '../../components';

const Landing = ({t, i18n}) => {
  return (
    <LandingLayout
      title="Tarjousmalli - Pohja, jonka avulla voit lähettää tarjouksia"
      language="fi"
      meta={[
        {name: 'description', content:'Tarjousmalli - Duuersin web-pohjainen tarjoustyökalu tarjoaa sinulle täydellisen esimerkki tarjouspohjan. Käytä esimerkki pohjaa tarjousten luomiseen ja lähettämiseen asiakkaalle. Aikaa siihen kuluu alle 60 sekuntia!'}, 
        {name: 'robots', content:'noindex, nofollow'}
      ]}
    >
      <Hero>
        <h1 className="blue font_second light font102 top45">Tarjousmalli</h1>
    		<div className="margin_auto top40 max_width770 blue light font22 text">
    			<p>Luo, lähetä ja hallinnoi tarjouksia napin painalluksella</p>
    		</div>
    		<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size50 transparent_blue border border_blue radius18 padding_sides35 sides25 top45">{t('menu.signup')}</a>
      </Hero>
      <Features title="Säästä aikaa. Säästä rahaa. Kasvata yritystäsi" subtitle="Haluamme tehdä tarjousten luomisesta mahdollisimman helppoa.">
 			 <Feature src="/images/features/proposal.png" name="Nopeat ja helpot tarjoukset" description="Etkö ole varma, miten tehdä ammattimaisia tarjouksia? Kyllästynyt käyttämään aina samaa vanhaa vanhaa Word-pohjaa? Siinä tapauksessa Duuers on tehty sinulle! Valmiit esimerkki tarjouspohjamme ovat juuri sitä mitä tarvitset."/>
       <Feature src="/images/features/messages.png" name="Räätälöidyt tarjouspohjat, jotka istuvat brändiisi." description="Unohda yleiset Word pohjat. Duuersin avulla voit lisätä oman logosi, brändivärisi, kuvasi ja tuotteesi, jopa videoita, jos haluat! Voit luoda useita eri tarjouspohjia jokaiselle tarjoamallesi palvelulle."/> 
       <Feature src="/images/features/proposalarchive.png" name="Säilytä kaikki tarjoukset samassa paikassa, aina saatavilla" description="Duuersin kanssa kaikki tarjouksesi säilytetään ja järjestetään niiden tilan perusteella (avoimet, hyväksytyt, hylätyt jne.). Jokaiselle tilalle on erilaisia suodattimia, ja voit etsiä tarjouksia asiakkaan tai tunnisteiden perusteella."/> 
       <Feature src="/images/features/mobile-friendly.png" name="Duuers mobiilissa (tulossa pian!)" description="Työsi on liikkeessä, joten tarvitset työkaluja, jotka toimivat aina ja missä tahansa. Duuersin avulla voit tarkastella, kommentoida ja hyväksyä tarjouksia puhelimellasi."/>
      </Features>
      <SignupDivider/>
      <References>
        <Reference name="Reeta Laamo" company="Red & Blue" image="/images/references/reeta_laamo_500x500.png">
         Duuers nopeuttaa ja helpottaa tarjousten tekemistä. Meillä virheiden määrä on vähentynyt, ja vastaanottajat ovat kiitelleet siistejä tarjouspohjia. Yhteen tarjoukseen unohdin väärän hinnan, mutta pystyin Duuersin avulla muokkaamaan lähettämääni tarjousta jo ennen kuin vastaanottaja oli avannut tarjouksen. Duuers tarjoaa näkymän siihen miten tarjouksia tulevaisuudessa pyöritellään. Suosittelen!
        </Reference>
        <Reference  name="Iiro Hänninen" company="Korttilinna" image="/images/references/iiro_500x500.png">
  			  Duuers oli nopea ja helppo ottaa käyttöön. Laatu ja näkyvyys ovat parantuneet huomattavasti ja myös asiakkaat ovat antaneet pointsit palvelusta! Käytännössä olen siirtänyt koko tarjoustyöskentelyni Duuersiin. Takaisin vanhaan ei ole paluuta!
  			</Reference>
  			<Reference  name="Jani Teräväinen" company="Työmaapalvelut Express" image="/images/references/jani_500x500.png">
  			  Duuersin merkitys kasvaa koko ajan! Tarjoustyöskentely on nopeutunut ja tehostunut huomattavasti ja kannustanut uudistamaan muitakin yrityksen osa-alueita. Olen jälleen askeleen lähempänä paperitonta toimistoa!
  			</Reference>
  			<Reference  name="Heikki Holmström" company="Munkkiniemen Remonttiapu">
  			  Duuers nopeuttaa tarjousten tekoaikaani puolella ja nopeutuu koko ajan. Tarjousten tekemisestä on tullut helppoa, ja erityisesti uuden tarjouksen aloittamisesta on tullut mukavaa!
  			</Reference>
      </References>
      <Pricing/>
      <BookDemo/>
      <Footer/>
    </LandingLayout>
);
};


export default withTranslation()(Landing);
